:root {
  --background: #0e0e0e;
  --text-primary: #fff;
  --background-image: url('./images/pop-art-dark.png');
  --accent: #ffffff;
  --border: #000000;
}

[data-theme='dark'] {
  --background: #ffffff;
  --text-primary: #000;
  --background-image: url('./images/pop-art-light.png');
  --accent: #000000;
  --border: #ffffff;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Roboto Condensed', sans-serif;
  background-color: var(--background);
}

ul {
  list-style-type: none;
}

.theme-toggle i {
  font-size: 2rem;
  cursor: pointer;
  color: var(--text-primary);
}

.theme-toggle {
  text-align: right;
  margin-right: 10%;
}

.app-container {
  background-image: var(--background-image);
  min-height: 768px;
  padding: 40px 0;
}

/* NAVBAR COMPONENT  */

nav {
  color: var(--text-primary);
  background-color: var(--background);
}

.nav-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  font-weight: 700;
  font-size: 2.25rem !important;
}

.nav-logo {
  color: var(--text-primary);
  text-decoration: none;
  transition: all 0.3s linear;
}

.nav-logo:hover {
  color: #ffad4c;
}

.nav-toggle {
  font-size: 2.5rem;
  color: var(--text-primary);
  background: transparent;
  border-color: transparent;
  transition: all 0.3s linear;
  cursor: pointer;
}

.nav-toggle:hover {
  transform: rotate(90deg);
}

.links a {
  color: var(--text-primary);
  font-size: 1rem;
  text-transform: capitalize;
  letter-spacing: 0.1rem;
  display: block;
  padding: 0.5rem 1rem;
  transition: all 0.3s linear;
}

.links a:hover {
  color: #2be5e5;
  padding-left: 1.5rem;
}

.social-icons {
  display: none;
  font-size: 2rem;
}

.links-container {
  height: 0;
  overflow: hidden;
  transition: all 0.3s linear;
  display: flex;
  align-items: center;
}

.show-container {
  height: 10rem;
  justify-content: center;
}

@media screen and (min-width: 800px) {
  .nav-center {
    max-width: 1170px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
  }

  .nav-header {
    padding: 0;
  }

  .nav-toggle {
    display: none;
  }

  .links-container {
    height: auto !important;
  }

  .links {
    display: flex;
  }

  .links a {
    padding: 0;
    margin: 0 0.5rem;
  }

  .links a:hover {
    padding: 0;
    background: transparent;
  }

  .social-icons {
    display: flex;
  }

  .social-icons a {
    margin: 0 0.5rem;
    color: var(--text-primary);
    transition: all 0.3s linear;
  }

  .social-icons a:hover {
    color: #2be5e5;
  }
}

/* PROJECTS COMPONENT  */

.portfolio_container {
  width: 100%;
  display: block;
}

.content_container {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.content_card {
  position: relative;
  margin: 0.5%;
  width: 450px;
  height: 282px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 90%;
  background: rgba(0, 0, 0, 0);
  transition: background 0.5s ease;
}

.content_card:hover .overlay {
  display: block;
  background: rgba(5, 4, 4, 0.3);
}

.card-images {
  position: absolute;
  width: 100%;
  height: auto;
  left: 0;
}

.portfolio-title {
  position: absolute;
  width: 450px;
  top: 120px;
  font-weight: 700;
  font-size: 30px;
  text-align: center;
  text-transform: uppercase;
  color: white;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.35s ease;
}

.content_card:hover .portfolio-title {
  opacity: 1;
}

.portfolio-category {
  position: absolute;
  width: 100px;
  height: 31.5px;
  top: 85%;
  left: 70%;
  padding-top: 1%;
  text-align: center;
  z-index: 1;
  border-radius: 5px 5px 0 0;
  color: white;
  transition: top 0.5s ease;
  opacity: 0;
}

.portfolio-category-animation {
  background-color: #ffad4c;
}
.portfolio-category-film {
  background-color: #2be5e5;
}
.portfolio-category-videography {
  background-color: #03e787;
}

.content_card:hover .portfolio-category {
  top: 222px;
  opacity: 1;
}

/* CATEGORY COMPONENT  */

.category-btn-container {
  display: flex;
  justify-content: center;
  padding-bottom: 40px;
}

.filter-btn {
  background: transparent;
  border-color: transparent;
  font-size: 1rem;
  text-transform: capitalize;
  margin: 0 0.5rem;
  letter-spacing: 1px;
  padding: 0.375rem 0.75rem;
  color: var(--text-primary);
  cursor: pointer;
  transition: all 0.3s linear;
  border-radius: 0.25rem;
}

.filter-btn.active {
  background-color: #e0e0e0;
  color: #333;
}

.filter-btn:hover {
  background: var(--accent);
  color: var(--border);
}

/* POP-UP  */
.pop_up_container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.925);
  padding-top: 1%;
}

.pop_up_body {
  width: 70%;
  height: auto;
  background: rgba(0, 0, 0, 0);
  margin: auto;
}

.pop_up_heade {
  width: 100%;
  text-align: right;
}

button {
  background-color: transparent;
  border: none;
  font-weight: 100;
  float: right;
}

.fa-times,
.fa-times-circle {
  font-size: 3rem;
  color: white;
}

/* EMBED  VIDEOS */
.video {
  width: 100%;
  padding-top: 3%;
}

.video-title {
  text-align: center;
  margin-top: 3%;
}

.video-title a {
  color: #2be5e5;
  font-weight: 700;
}

.video-description {
  padding: 0 15% 0 15%;
  color: white;
}

/* ABOUT  */
.about_container {
  display: flex;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
}

.image_container {
  width: 50%;
  height: auto;
  padding: 50px 0 50px 20px;
}

.about-image {
  width: 100%;
  border-radius: 5px;
}

.information_container {
  width: 50%;
  height: auto;
  padding: 80px 60px;
  color: var(--text-primary);
}

.information_container,
h1 {
  text-align: center;
  padding-bottom: 20px;
}

.information_container,
p {
  text-align: left;
  padding-bottom: 20px;
}

.information_container a {
  color: #2be5e5;
}

.line {
  width: 10%;
  height: 0.5px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  background-color: var(--accent);
  margin-bottom: 40px;
}

/* FOOTER  */
footer {
  width: 100%;
  padding: 2%;
  background-color: var(--background);
  text-align: center;
}

footer p {
  margin: 1px;
  text-align: center;
  color: var(--text-primary);
}

footer p a {
  text-decoration: none;
  color: var(--text-primary);
  font-size: 18px;
}
footer p a:hover {
  color: #2be5e5;
}

.social-icons-footer {
  width: 100px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  font-size: 1.5rem;
  margin-bottom: 0.5%;
}

.social-icons-footer li a {
  color: var(--text-primary);
}

.social-icons-footer li a:hover {
  color: #2be5e5;
}

/**************
*    tablet   *
**************/
@media screen and (min-width: 601px) and (max-width: 800px) {
  .pop_up_body {
    width: 90%;
    height: auto;
  }

  .about_container {
    width: 80%;
  }

  .information_container {
    padding: 50px 20px;
  }

  .video-description {
    padding: 0 3% 0 3%;
  }
}

/**************
*    Mobile   *
**************/
@media screen and (max-width: 600px) {
  .pop_up_body {
    width: 90%;
    height: auto;
  }

  .about_container {
    width: 95%;
  }

  .information_container {
    padding: 20px 5px;
  }

  .video-description {
    padding: 0;
  }

  .filter-btn {
    margin: 0;
    padding: 0.375rem 0.5rem;
  }

  .content_card {
    height: 180px;
  }

  .portfolio-title {
    width: 300px;
    top: 60px;
  }

  .content_card:hover .portfolio-category {
    top: 133px;
  }

  .video {
    width: 100%;
    height: 250px;
  }

  .video-title {
    text-align: center;
    margin-top: 0%;
  }

  .video-title a {
    color: #2be5e5;
    font-weight: 700;
  }

  .video-description {
    padding: 0 5% 0 5%;
  }

  .about_container {
    flex-direction: column;
  }

  .image_container {
    margin-left: auto;
    margin-right: auto;
    padding: 0;
  }

  .information_container {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .overlay {
    height: 90%;
  }
}

@media (max-device-width: 540px) {
  .portfolio-category {
    left: 65%;
  }
}

/* @media (max-device-width: 320px) {

  .portfolio-category {
    left: 65%;
  }

  .portfolio-title {
    width: 250px;
    top: 20px;
    border: 1px solid red;
  }
  
  .overlay {
    height: 65%;
  }
  
} */
